<template>
  <div class="center-title">
    <div class="background">
      <!-- {{ backgroundTitle }} -->
      <div class="imgBox">
        <img src="../../assets/imgs/product-6.png" alt="" class="top-img">
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="suggest" v-html="suggest"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundTitle: String,
    title: String,
    suggest: String,
  },
};
</script>
<style lang="less" scoped>
.center-title {
  width: 100%;
  // overflow-x: hidden;
  text-align: center;
  .background {
    // width: 1060px;
    // height: 170px;
    margin: 0 auto;
    color: #f6f6f6;
    font-size: 200px;
    position: relative;
    font-weight: 600;
    font-family: "黑体";
    .imgBox{
       width: 182px;
      height: 161px;
      margin-left: 126px;
      margin-top: 97px;
      display: flex;
    }
    .top-img{
      width: 100%;
    }
    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      // font-size: 26px;
      font-size: 35px;
      color: #000;
      position: absolute;
      top: 98px;
      // transform: translateY(-50%);
      text-align: left;
      margin-left: 160px;
      font-weight: 500;
      font-family: "苹方-简 中黑体";
      src: url('../../assets/fonts/DIN Alternate Bold.ttf');
    }
    .suggest {
      width: 100%;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      // max-width: 1400px;
      margin: 39px auto 0;
      // margin-left: 160px;
      text-align: left;
      padding: 0 160px;
      line-height: 40px;
      p {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
