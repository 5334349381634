<template>
  <div class="product-cover">
   <div class="imgBox">
      <img
      class="header-img"
      style="width: 100%;font-size:0"
      src="../../assets/imgs/company-header.png"
      alt=""
    />
   </div>
    <page-header
      :tabs="tabs"
      :name="name"
      :active-tab="activeTab"
    ></page-header>
    <div class="suggest">
      <property-title 
        :background-title="enPageTitle"
        :title="pageName"
        :suggest="suggest"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="merit-cover">
        <div class="merit-item" v-for="(item, idx) in merits" :key="`${idx}`">
          <img :src="item.img" alt="" class="item-img" />
          <div class="item-name">{{ item.name }}</div>
          <div class="item-suggest">{{ item.suggest }}</div>
        </div>
      </div>
     <div class="Lbox-cover">
       <div class="Lbox-title">
         <div class="line"></div>
         <div class="Ltitle">
            <h4>爱情地产5L产品创新体系</h4>
            <p>5L PRODUCT INNOVATION SYSTEM</p>
          </div>
          <div class="partBox">
            <div class="FistL">
              
                <div class='sTitle'>L</div>
               <div class="FText">
                 <p>
                   是love，更有爱，贴心的服务理念<br> 是light，更轻盈的生活方式，也有点亮，激活的含义<br> 是life，更全维度的健康生活体系
                 </p>
               </div>
            </div>
            <div class="Litem" v-for="(item, idx) in lpart" :key="idx">
                <div class='sTitle'><span>L</span>{{item.sTitle1}}</div>
                <div class="bTitle">{{item.bTitle}}</div>
                <div class='sTitle'>{{item.sTitle}}</div>
                <div class="sLine"></div>
                <div class="Ltext">
                  <p v-for="(subItem,subIndex) in item.Ltext" :key="subIndex">{{subItem}}</p>
                </div>
            </div>
          </div>
       </div>
     </div>
      <div class="master-cover">
        <div class="top-cover">
          <div class="top-cover-content">
            <img src="../../assets/imgs/product-6.png" alt="" class="top-dui" />
            <div class="top-left">
              <div
                class="top-name"
                v-for="(item, idx) in masterInfo.names"
                :key="`${idx}`"
              >
                {{ item }}
              </div>
              <div class="top-suggest">{{ masterInfo.suggest }}</div>
            </div>
            <div class="top-right">
              <div class="top-right-bg"></div>
              <img
                src="../../assets/imgs/product-7.png"
                alt=""
                class="top-right-img"
              />
            </div>
            <img
              src="../../assets/imgs/product-12.png"
              alt=""
              class="bottom-dui"
            />
          </div>
        </div>
        <div class="bottom-cover">
          <div
            class="bottom-item"
            v-for="(item, idx) in masterList"
            :key="`${idx}`"
          >
            <img src="../../assets/imgs/product-8.png" alt="" class="item-bg" />
            <div class="item-content">
              <img :src="item.icon" alt="" class="item-icon" />
              <div class="item-name">{{ item.name }}</div>
              <div
                class="item-suggest"
                v-for="(sg, _idx) in item.suggest"
                :key="`${idx}_${_idx}`"
              >
                {{ sg }}
              </div>
            </div>
          </div>
        </div>
      </div>
       <center-title
          title="九衡科技  创新绿色智慧城市新模式"
          suggest="以低碳、科技双轮技术驱动，提供领先的城市发展解决方案与服务。在绿色智造、绿色建筑、绿色能源等领域全面发力，助力城市新发展。"
        ></center-title>
       <div class="sell-cover">
         <div class="ImgBox">
           <img src="../../assets/imgs/product-12.png" alt="">
         </div>
        <img src="../../assets/imgs/product-4.png" alt="" class="sell-bg" />
        <div class="sell-content">
          <div class="sell-item" v-for="(sell, idx) in sells" :key="`${idx}`">
            <div class="name-img">
              <img
                src="../../assets/imgs/product-5.png"
                alt=""
                class="item-img"
              />
              <span class="item-name">{{ sell.name }}</span>
            </div>
            <div class="sell-suggest">{{ sell.suggest }}</div>
          </div>
        </div>
      </div>
      <div class="suggest" style="width:100%;">
        <center-title
          title="以爱陪伴  营造多彩生活新体系"
          suggest="释放人与空间的价值，演绎生活的奇妙多彩。旨在把所有对品质⽣活的理解，⽤诚信和温度注⼊到空间之中，深度服务⽣活，打造温暖多彩的生活体系。"
        ></center-title>
      </div>
      <div class="card-cover">
        <div class="Imgbox">
          <img src="../../assets/imgs/product-12.png" alt="">
        </div>
        <div class="card-item" v-for="(item, idx) in cards" :key="`${idx}`">
          <img :src="item.img" alt="" class="card-bg" />
          <div class="item-content" :class="`item-content-${idx}`">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-suggest">{{ item.suggest }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PRODUCT_SUGGEST,
  PRODUCT_TABS,
  PRODUCT_NAME,
  PRODUCT_MERITS,
  PRODUCT_SELLS,
  PRODUCT_MASTER_INFO,
  PRODUCT_MASTER_LIST,
  PRODUCT_CARD_LIST,
  PRODUCT_LPART
} from "./config";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
import CenterTitle from '@/components/common/CenterTitle.vue'
export default {
  components: {
    PropertyTitle,
    PageHeader,
    CenterTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      suggest: PRODUCT_SUGGEST,
      tabs: PRODUCT_TABS,
      name: PRODUCT_NAME,
      activeTab: PRODUCT_TABS[0],
      merits: PRODUCT_MERITS,
      sells: PRODUCT_SELLS,
      masterInfo: PRODUCT_MASTER_INFO,
      masterList: PRODUCT_MASTER_LIST,
      cards: PRODUCT_CARD_LIST,
      lpart:PRODUCT_LPART
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  },
};
</script>

<style scoped lang="less">

.product-cover {
  padding-bottom: 120px;
  .header-img {
    width: 100%;
    padding-top: 140px;
  }
  .main-content {
    margin-top: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .merit-cover {
      display: flex;
      // align-items: center;
      justify-content: space-around;
      width: 1600px;
      padding-bottom: 61px;
      .merit-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-img {
          width: 112px;
          height: 112px;
        }
        .item-name {
          font-size: 28px;
          line-height: 40px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-top: 22px;
        }
        .item-suggest {
          width: 410px;
          font-size: 22px;
          line-height: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: center;
          margin-top: 17px;
          padding: 0 55px;
        }
      }
    }
    .Lbox-cover{
      width: 100%;
      background: url('../../assets/imgs/bgLine.png') no-repeat top left;
      padding-top:66px; 
      padding-bottom:101px;
      .Lbox-title{
        padding-left: 162px;
        .line{
          width: 70px;
          height: 6px;
          margin-bottom: 39px;
          background: #E95506;
        }
        .Ltitle{
          h4{
            font-size: 35px;
            color: #000000;
            line-height: 49px;
            margin-bottom: 7px;
          }
          p{
            font-size: 20px;
            color: #CCCCCC;
            line-height: 28px;
          }
        }
        .FistL{
          width: 444px;
          font-size: 50px;
          line-height: 70px;
          color: #E95506;
          margin-top: 60px;
          margin-left: 40px;
          box-sizing: border-box;
          position: relative;
          .FText{
            max-width: 388px;
            font-size: 22px;
            color: #666666;
            line-height: 40px;
            z-index: 99;
            background: url('../../assets/imgs/7.png') no-repeat 289px 0px;
          }
        }
        .partBox{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-right: 161px;
          box-sizing: border-box;
        }
        .Litem{
          width:485px;
          padding: 45px 66px 37px 67px;
          background: #fff;
          text-align: center;
          // margin-right: 71px;
          margin-bottom: 39px;
          .sTitle{
            color: #CCCCCC;
            font-size: 20px;
            line-height: 28px;
            span{
              color: #E95506;
              font-size: 30px;
              line-height: 42px;
            }
          }
          .sLine{
            width: 151px;
            height: 0.5px;
            background: rgb(230, 226, 226);
            margin: 24px auto 0;
          }
          .bTitle{
            font-size: 30px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 15px;
            font-weight: 500;
            font-family: '苹方-简 中黑体';
            src: url('../../assets/fonts/PINGFANG MEDIUM.TTF');
          }
          .Ltext{
            min-width: 352px;
            font-size: 22px;
            line-height: 40px;
            color: #333333;
            margin-top: 12px;
            p{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .sell-cover {
      .ImgBox{
        width: 182px;
        height: 161px;
        position: absolute;
        right: 37px;
        bottom: 0;
        opacity: 0.1;
      }
      margin-top: 39px;
      width: 100%;
      position: relative;
      .sell-bg {
        width: 100%;
        height: 735px;
      }
      .sell-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 188px;
        .sell-item {
          width: 33.3%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 40px 0;
          &:nth-child(2n) {
            // justify-content: flex-end;
          }
          .name-img {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
            .item-img {
              width: 122px;
              height: 122px;
            }
            .item-name {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-size: 28px;
              line-height: 40px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
          .sell-suggest {
            margin-left: 10px;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            width: 315px;
            flex-grow: 0;
            flex-shrink: 0;
            line-height: 32px;
          }
        }
      }
    }
    .master-cover {
      margin-top: 60px;
      position: relative;
      .bottom-cover {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        margin-top: 70px;
        .bottom-item {
          position: relative;
          font-size: 0;
          box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.05);
          margin-left: 24px;
          &:first-child {
            margin-left: 0;
          }
          .item-bg {
            width: 518px;
            height: 228px;
          }
          .item-content {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            padding: 31px 0 0 42px;
            display: flex;
            flex-direction: column;
            .item-icon {
              width: 36px;
              height: 36px;
            }
            .item-name {
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 42px;
              color: #333333;
              margin-bottom: 10px;
            }
            .item-suggest {
              font-size: 22px;
              line-height: 35px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 35px;
            }
          }
        }
      }
      .top-cover {
        display: flex;
        .top-cover-content {
          display: flex;
          position: relative;
        }
        .top-left {
          padding-top: 166px;
        }
        .top-name {
          font-size: 35px;
          line-height: 49px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          position: relative;
          z-index: 2;
        }
        .top-suggest {
          margin-top: 43px;
          font-size: 22px;
          line-height: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          width: 526px;
        }
        .top-right {
          position: relative;
          margin-left: 142px;
          .top-right-bg {
            position: absolute;
            top: 0;
            left: -76px;
            width: 1167px;
            height: 572px;
            background: #f8f8f8;
          }
          .top-right-img {
            width: 925px;
            height: 552px;
            position: relative;
            z-index: 2;
            margin-top: 114px;
          }
        }
      }
      .top-dui {
        width: 182px;
        height: 161px;
        position: absolute;
        left: -34px;
        top: 68px;
      }
      .bottom-dui {
        position: absolute;
        bottom: -400px;
        right: -140px;
        width: 182px;
        height: 161px;
      }
    }
    .card-cover {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      .Imgbox{
        position: absolute;
        right: 76px;
        bottom: -81px;
      }
      .card-item {
        margin-right: 56px;
        position: relative;
        margin-top: 111px;
        &:nth-child(2n-1) {
          margin-left: 0;
        }
        .card-bg {
          width: 772px;
          height: 433px;
        }
        .item-content {
          padding: 32px 0 0 40px;
          width: 394px;
          height: 186px;
          border-radius: 5px;
          box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.05);
          background: #fff;
          position: absolute;
          &.item-content-0 {
            top: -35px;
            left: 0;
          }
          &.item-content-1 {
            bottom: -35px;
            right: 0;
          }
          &.item-content-2 {
            top: -35px;
            left: 0;
          }
          &.item-content-3 {
            bottom: -35px;
            right: 0;
          }
          .item-name {
            font-size: 28px;
            line-height: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .item-suggest {
            margin-top: 17px;
            font-size: 22px;
            line-height: 30px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
            width: 316px;
          }
        }
      }
    }
  }
}
</style>
