export const PRODUCT_SUGGEST =
  "以地产开发、商业运营、物业服务为核心业务，实现多元化业务经营。创领绿色智慧城市新模式，探索地产运营新品类，营造多彩生活新体系，这是爱情地产对品牌的创新，更是对未来生活的革新。";

// tabs
export const PRODUCT_TABS = ["产品创新"];

export const PRODUCT_NAME = "产品创新";

// 产品优点
export const PRODUCT_MERITS = [
  {
    img: require("../../assets/imgs/product-1.png"),
    name: "新模式",
    suggest:
      "赋能城市创新价值",
  },
  {
    img: require("../../assets/imgs/product-2.png"),
    name: "新品类",
    suggest:
      "低碳空间+科技赋能+品质标杆",
  },
  {
    img: require("../../assets/imgs/product-3.png"),
    name: "新体系",
    suggest:
      "用诚信和温度注入的服务之中深度构建多彩生活",
  },
];
// 
export const PRODUCT_LPART = [
  {
    sTitle1: "OW-CARBON",
    bTitle: "·低碳空间·",
    sTitle: "低碳技术应用，更环保的发展方式",
    Ltext: ["氢能建筑·直流建筑", " 零碳光伏空调·光伏光热综合利用", " 光储充V2G·地源热泵"]
  },
  {
    sTitle1: "IBERTY",
    bTitle: "·自由人居·",
    sTitle: "九衡科技系统，更高效的人居空间",
    Ltext: ["衡温·衡湿·衡氧", " 衡静·衡洁·衡光", " 衡压·衡智·衡心"]
  },
  {
    sTitle1: "EISURE",
    bTitle: "·舒适社区·",
    sTitle: "绿色健康模拟，更舒适的社区环境",
    Ltext: ["室外光照模拟·室外风环境模拟 ", "室外噪声模拟·室内采光模拟", " 室内眩光模拟·建筑能耗模拟"]
  },
  {
    sTitle1: "INK",
    bTitle: "·交互体验·",
    sTitle: "Ai智慧平台，更轻松的链接体验",
    Ltext: ["一站式生活平台·AI算法物管 ", " 智慧能耗管理·车牌识别闸机", "人脸识别闸机·智能防疫系统"]
  },
  {
    sTitle1: "IVELY",
    bTitle: "·活力服务·",
    sTitle: "多元创新服务，更奇妙多彩的生活",
    Ltext: ["共享邻里空间·共享共创社区  ", "首席生活官·乐活课堂", "  邻里生活节·潮生活文化季"]
  }
]

// 产品卖点
export const PRODUCT_SELLS = [
  {
    suggest: "打造室内20-26°四季如春的均衡温度，水平、垂直温差小于2°C。",
    name: "衡温",
  },
  {
    suggest: "通过加湿除湿系统，室内全年湿度可保持在30%-70%，不畏酷暑严寒。",
    name: "衡湿",
  },
  {
    suggest:
      "置换新风系统每两小时至少置换一次，使室内空气24小时保持新鲜。",
    name: "衡氧",
  },
  {
    suggest:
      "通过同层排水、低噪音设备暗装方式，在家即意味着拥有不被打扰的世界，保持室内噪音不高于35分贝。",
    name: "衡静",
  },
  {
    suggest:
      "独立空气处理系统，三层过滤，PM2.5过滤效率达到95%以上，采用独特地送上排的方式，创造洁净的呼吸环境。",
    name: "衡洁",
  },
  {
    suggest: "爱情地产人、车、家智慧社区云服务平台，提供7*24小时贴心智慧服务。",
    name: "衡智",
  },
  {
    suggest:
      "通过中空LOW-E玻璃、遮阳技术，有效调节光环境。",
    name: "衡光",
  },
  {
    suggest:
      "室内保持约5Pa的微正压环境，减少污染通过门窗缝隙进入。",
    name: "衡压",
  },
  {
    suggest: "资深技术研发团队，专家护航，用心提供技术和品质保证。",
    name: "衡心",
  },
];


//
export const PRODUCT_MASTER_INFO = {
  names: ["匠心精筑", "探索地产运营新品类"],
  suggest:
    "综合运用低碳、科技、新能源等技术，从客户视角出发，构建精准用户的行为模型，以技术为驱动，深度参与城市运营，探索住宅开发、商业运营、物业服务的全新可能。",
};

//
export const PRODUCT_MASTER_LIST = [
  {
    name: "-共享共创社区",
    suggest: [
      "打破空间的隔阂，让人与人更加亲密",
      "开放共享的生活共同体，你是主人更是家人",
    ],
    icon: require("../../assets/imgs/product-9.png"),
  },
  {
    name: "-共享邻里空间",
    suggest: ["多项目采用架空层设计，增加户外活动空间","举办丰富多彩的业主活动，让邻里不再陌生"],
    icon: require("../../assets/imgs/product-10.png"),
  },
  {
    name: "-共创社区文化",
    suggest: ["打造儿童友好社区，关注孩子的成长","丰富的休闲娱乐活动，打造健康共享的社区文化"],
    icon: require("../../assets/imgs/product-11.png"),
  },
];
//


//
export const PRODUCT_CARD_LIST = [
  {
    name: "首席生活官",
    suggest: "不同的角色和职能，用人性化服务为业主营造品质生活体验。",
    img: require("../../assets/imgs/product-13.png"),
  },
  {
    name: "乐活课堂",
    suggest: "为社区定向设计的共享进步空间，让精神在生活中日渐丰盈。",
    img: require("../../assets/imgs/product-14.png"),
  },
  {
    name: "邻里生活节",
    suggest: "定期举办的社区主题生活节，打造业主专属节日和生活方式。",
    img: require("../../assets/imgs/product-15.png"),
  },
  {
    name: "潮生活文化季",
    suggest: "爱情地产年度文化盛宴，邀请业主们参与共创，拥抱多彩生活。",
    img: require("../../assets/imgs/product-16.png"),
  },
];
